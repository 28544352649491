















































































































import Vue from "vue";

import { mapMutations } from "vuex";
import { login, PostRequestPasswordReset } from "@/api/auth";
import { GetUser } from "@/api/user";
import { setAuthToken } from "@/common/auth";
import { GetResponseErrors } from "@/common/utils";
import TheSnackbar from "@/components/TheSnackbar.vue";

export default Vue.extend({
  name: "Login",
  metaInfo: {
    title: "Iniciar sesión",
  },
  components: {
    TheSnackbar,
  },
  data: () => ({
    valid: false,
    isLoading: false,
    form: {
      username: "",
      password: "",
    },
    resetForm: {
      isLoading: false,
      isVisible: false,
      email: "",
    },
    phoneRules: [(value: string) => !!value],
  }),
  methods: {
    ...mapMutations("auth", ["set"]),
    async handleSubmit() {
      this.isLoading = true;
      try {
        const tokenRes = await login(this.form);
        setAuthToken(tokenRes.data.token);
        // const userRes = await GetUser();

        this.set({
          user: tokenRes.data.user,
          token: tokenRes.data.token,
        });

        this.$root.$emit("user:logged");

        this.$router.push("/");
      } catch (err: any) {
        const snack: any = this.$refs.snackbar;
        snack.show(err.response.data.username);
      }

      this.isLoading = false;
    },
    async handleRecoverPassword() {
      this.resetForm.isLoading = true;
      try {
        await PostRequestPasswordReset(this.resetForm.email);
        this.$router.push({
          path: "recover-password",
          query: {
            email: this.resetForm.email,
          },
        });
      } catch (err) {
        const snack: any = this.$refs.snackbar;
        snack.show(GetResponseErrors(err));
      }

      this.resetForm.isLoading = false;
    },
  },
});
